/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { FC } from "react"
import { Link } from "gatsby"
import { HeaderInfo } from "../components/HeaderInfo"

interface indexProps {}

const index: FC<indexProps> = props => {
    return (
        // <Layout>
        <div
            sx={{
                textAlign: "center",
                variant: "text.copy",
                fontSize: [3, 4],
                padding: [3, 4, 5],
            }}
        >
            <HeaderInfo appendTitle="Página no encontrada"></HeaderInfo>
            <div sx={{ textAlign: "left", display: "inline-block" }}>
                Lo sentimos, esta página no se pudo encontrar. Ve a la
                <Link sx={{ variant: "link.normal" }} to="/">
                    {" "}
                    página de inicio
                </Link>
                .
            </div>
        </div>
        // </Layout>
    )
}

export default index
